import { combineReducers } from 'redux';

import documentCacheReducer from '@tmap/mmm-core/redux/documentCache/documentCacheReducer';
import viewsReducer from '@tmap/mmm-core/redux/views/viewsReducer';
import thunktankReducer from '@tmap/mmm-core/redux/thunktank/thunktankReducer';

import authReducer from './auth';
import userReducer from './user';
import filtersReducer from './filters';
import chatsReducer from './chats';
import contentReducer from './content';
import fulfillmentProfilesReducer from './fulfillmentProfiles';

const rootReducer = combineReducers({
  authStore: authReducer,
  userStore: userReducer,
  filterStore: filtersReducer,
  contentStore: contentReducer,
  moverChats: chatsReducer,
  documentCache: documentCacheReducer,
  views: viewsReducer,
  thunktank: thunktankReducer,
  fulfillmentProfiles: fulfillmentProfilesReducer,
});

export default rootReducer;