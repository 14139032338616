export const FETCH_FULFILLMENT_PROFILES = 'FETCH_FULFILLMENT_PROFILES';
export const RECEIVE_FULFILLMENT_PROFILES = 'RECEIVE_FULFILLMENT_PROFILES';

const defaultState = {
  isLoading: false,
  profiles: undefined,
};

const fulfillmentProfilesReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case FETCH_FULFILLMENT_PROFILES:
      return {
        ...state,
        isLoading: true,
      };
    case RECEIVE_FULFILLMENT_PROFILES:
      return {
        ...state,
        isLoading: false,
        profiles: action.profiles,
      };
    default:
      return state;
  }
};

export default fulfillmentProfilesReducer;
